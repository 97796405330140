define("discourse/plugins/discourse-calendar/discourse/connectors/discovery-list-container-top/category-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.siteSettings.calendar_categories_outlet === "discovery-list-container-top";
    }
  };
});