define("discourse/plugins/discourse-calendar/discourse/templates/connectors/discovery-list-container-top/category-events", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="category-events-calendar"></div>
  */
  {
    "id": "01E+Hy/O",
    "block": "[[[10,0],[14,1,\"category-events-calendar\"],[12],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/templates/connectors/discovery-list-container-top/category-events.hbs",
    "isStrictMode": false
  });
});